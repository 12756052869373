import React, { useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { addLeadTag, updateLeadTag } from '../../../../services/api/leads.api';
import { actions, LeadsContext } from '../../store/leads.store';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';

export default function CreateNewListModal({
  toggle,
  setToggle,
  label,
  tracking = {},
  setCurrentToggle,
  onTagUpdated,
  handleCreateList,
}: any) {
  //@ts-expect-error
  const { state, dispatch } = useContext(LeadsContext);
  const [listName, setListName] = useState((label && label.value) || '');
  const [creationLoading, setCreationLoading] = useState(false);
  const [error, setError] = useState('');

  const handleEditLabel = async () => {
    setCreationLoading(true);
    const { data, statusCode } = await updateLeadTag({
      ...label,
      clientDate: new Date(),
      value: listName,
    });
    setCreationLoading(false);

    if (statusCode == 200) {
      setToggle(false);
      //@ts-expect-error
      if (data && data.tag && onTagUpdated) {
        //@ts-expect-error
        onTagUpdated(data.tag);
      }
    } else {
      //@ts-expect-error
      setError(data.messageTranslated || data.message);
    }
  };

  return (
    <Modal show={toggle} onHide={() => setToggle(false)} className="fade create-new-modal">
      <Modal.Header closeButton className="modal-header border-0">
        <div className="modal-inner-header">
          <h4 className="title">
            {label ? (
              <FormattedMessage id={'editList'} />
            ) : (
              <FormattedMessage id={'createNewList'} />
            )}
          </h4>
        </div>
      </Modal.Header>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          label ? handleEditLabel() : handleCreateList && handleCreateList(listName);
        }}
      >
        <Modal.Body className="modal-body p-0">
          <div className="inner-content">
            <div className="form-group">
              <label>
                {label ? (
                  <FormattedMessage id={'editList'} />
                ) : (
                  <FormattedMessage id={'home.leads.enterListNameToSave'} />
                )}
              </label>

              <input
                autoFocus={true}
                type="text"
                value={listName}
                onChange={(e) => setListName(e.target.value)}
              />
            </div>
          </div>
          {error && (
            <div
              className="btn-grp text-center  error  kaspr-form-text-error"
              style={{ top: 7, width: '90%' }}
            >
              {error + ' '}
            </div>
          )}
        </Modal.Body>

        <Modal.Footer className="modal-footer border-0">
          <button
            className="btn2 mr14"
            type={'button'}
            onClick={() => setToggle(false)}
            data-tracking-id={tracking.cancel}
          >
            <FormattedMessage id={'cancel'} />
          </button>

          <button
            className="btn1 "
            type={'submit'}
            data-tracking-id={tracking.action}
            style={listName ? {} : { opacity: 0.5 }}
            disabled={creationLoading || !listName}
          >
            {label ? <FormattedMessage id={'edit'} /> : <FormattedMessage id={'create'} />}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

import React, { useState, useContext, useEffect } from 'react';
import AllListsNav from '../AllListsNav/AllListsNav';
import './AllLists.css';
import LeadsPanel from '../../LeadsPanel/LeadsPanel';
import { credits } from '../../../../redux/reducers/credits';
import { Animation } from '../../../../components';
import { FormattedMessage, useIntl } from 'react-intl';
import { deleteLeadTag, searchLeadTag } from '../../../../services/api/leads.api';
import { ModalMessage } from '../../../../components';
import CreateNewListModal from '../CreateNewListModal/CreateNwListModal';
import { LeadsContext, actions } from '../../store/leads.store';
import { PaginationInput } from '../../../../components';
import { capitalizeFirstLetter } from '../../../../services/utils/tools';
import { getAccounts } from '../../../../services/api/integrations.api';
import { checkIsPlanLimited } from '../../../../services/utils/checkPermissions';
import DataNotFound from '../../../../components/DataNotFound/DataNotFound';
import { ToastContainer } from 'react-bootstrap';

interface Tag {
  _id: string;
  value: string;
  addedBy: {
    memberId: string;
    email: string;
    firstName?: string;
    lastName?: string;
  };
  leadCount: number;
  lastEditedAt: string;
  name?: string;
}

const AllLists = ({
  organizationCredits,
  planPermissions,
  allTags,
  setOrganization,
  profilePermissions,
  errorMessage,
  profile,
  checkPlanPermissions,
  checkMemberPermissions,
  isVersion2,
  remainingCredits,
  organization,
  hidePersonalEmails,
  exportRemaining,
  hasPermission,
  organizationPlanPermissions,
}: any) => {
  //@ts-expect-error
  const { state, dispatch } = useContext(LeadsContext);

  const [filteredTags, setFilteredTags] = useState<Tag[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOwner, setSelectedOwner] = useState<Tag['addedBy'] | null>(null);
  const [showDetailedList, setShowDetailedList] = useState(false);
  const [activeTagId, setActiveTagId] = useState<string | null>(null);
  const [listToDelete, setListToDelete] = useState<Tag | null>(null);
  const [listToEdit, setListToEdit] = useState<Tag | null>(null);
  const [selectedList, setSelectedList] = useState<Tag | null>(null);
  const [account, setAccount] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Tag pagination
  const [tagPage, setTagPage] = useState(1);

  const intl = useIntl();

  const owners = filteredTags
    .map((tag: Tag) => tag.addedBy)
    .filter(
      (owner: Tag['addedBy'], index: number, self: Tag['addedBy'][]) =>
        index === self.findIndex((o) => o.memberId === owner.memberId),
    );

  const planIsLimitedPermission = checkIsPlanLimited({
    permissions: planPermissions,
    name: 'Tags',
  });

  useEffect(() => {
    getAccounts().then(({ data }) => {
      //@ts-expect-error
      if (data && data.accounts && data.accounts.gmail && data.accounts.gmail.gmailEmail)
        setAccount(true);
    });
    return () => {
      setAccount(false);
    };
  }, []);

  // Get filtered tags
  const fetchTagsFromAPI = async (
    search = '',
    owner: Tag['addedBy'] | null = null,
    page = 1,
    resultsPerPage = 10,
  ) => {
    const organizationId = organization && organization._id;
    const memberId = owner ? owner.memberId : null;
    const sortBy = 'clientDate';

    const { data, statusCode } = await searchLeadTag({
      organizationId,
      search,
      memberId,
      resultsPerPage,
      page: page - 1,
      sortBy,
    });

    //@ts-expect-error
    if (statusCode === 200 && data && data.tags) {
      //@ts-expect-error
      setFilteredTags(data.tags);
      dispatch({
        type: actions.GET_ALL_LISTS_PAGES,
        //@ts-expect-error
        payload: data.pages,
      });
    } else {
      setFilteredTags([]);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    setTagPage(1);
    fetchTagsFromAPI(term, selectedOwner);
  };

  const handleOwnerSelect = (owner: Tag['addedBy'] | null) => {
    setSelectedOwner(owner);
    fetchTagsFromAPI(searchTerm, owner);
  };

  const handleListSelected = (tag: Tag) => {
    setShowDetailedList(true);
    setSelectedList(tag);
  };

  const handleActions = (e: React.MouseEvent, tagId: string) => {
    e.stopPropagation();
    setActiveTagId((prev) => (prev === tagId ? null : tagId));
  };

  const handleDeteleTagList = async (tag: Tag) => {
    const { statusCode } = await deleteLeadTag({
      _id: tag._id,
    });

    if (statusCode === 200) {
      setFilteredTags((prevTags) => {
        const newTags = prevTags.filter((t) => t._id !== tag._id);
        dispatch({
          type: actions.GET_ALL_TAGS,
          payload: newTags,
        });
        return newTags;
      });
      setListToDelete(null);
    }
  };

  const handleTagUpdated = () => {
    // Redo fetching after updating
    fetchTagsFromAPI(searchTerm, selectedOwner, tagPage);
  };

  // Handle pagination for tags
  const handleTagsGoToPage = (newPage: number) => {
    setTagPage(newPage);
    fetchTagsFromAPI(searchTerm, selectedOwner, newPage);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`;
  };

  // // Get all tags initially
  useEffect(() => {
    setIsLoading(true);
    fetchTagsFromAPI('', null, tagPage);
    setIsLoading(false);
  }, []);

  return (
    <div className="all-lists-ctn">
      {filteredTags.length === 0 && !isLoading ? (
        <>
          <AllListsNav
            handleSearchChange={handleSearchChange}
            searchTerm={searchTerm}
            handleOwnerSelect={handleOwnerSelect}
            selectedOwner={selectedOwner}
            owners={owners}
            showDetailedList={showDetailedList}
            setShowDetailedList={setShowDetailedList}
            selectedList={selectedList ? selectedList : null}
            setFilteredTags={setFilteredTags}
          />
          <ToastContainer />
          <DataNotFound
            style={{ paddingTop: '30px' }}
            image={'/assets/images/img_empty_no_leads.png'}
            elements={
              <div
                style={{ marginTop: '0' }}
                className="join-organization-inner join-organization-page"
              >
                <h4>
                  <FormattedMessage id={'leadsLists.noListsYet'} />
                </h4>

                <p className="desc">
                  <FormattedMessage id={'leadsLists.createANewListToManageLeads'} />
                </p>
              </div>
            }
          />
        </>
      ) : (
        <>
          <AllListsNav
            handleSearchChange={handleSearchChange}
            searchTerm={searchTerm}
            handleOwnerSelect={handleOwnerSelect}
            selectedOwner={selectedOwner}
            owners={owners}
            showDetailedList={showDetailedList}
            setShowDetailedList={setShowDetailedList}
            selectedList={selectedList ? selectedList : null}
            setFilteredTags={setFilteredTags}
            onTagUpdated={handleTagUpdated}
          />
          {!showDetailedList ? (
            <div className="all-lists">
              <table>
                <thead className="all-lists__head">
                  <tr>
                    <th className="all-lists__head__thead-first">
                      <FormattedMessage id="home.list.name" />
                    </th>
                    <th className="all-lists__head__thead">
                      <FormattedMessage id="lead(s)" />
                    </th>
                    <th className="all-lists__head__thead">
                      <FormattedMessage id="organization.owner" />
                    </th>
                    <th className="all-lists__head__thead">
                      <FormattedMessage id="leadsLists.lastUpdated" />
                    </th>
                    <th className="all-lists__head__thead">
                      <FormattedMessage id="leadsLists.actions" />
                    </th>
                  </tr>
                </thead>
                <tbody className="all-lists__body">
                  {filteredTags.map((tag: Tag) => {
                    const isDisabled = tag.leadCount === 0;

                    return (
                      <tr
                        className={`all-lists__body__trow ${isDisabled ? 'all-lists__body__trow--disabled' : ''}`}
                        key={tag._id}
                      >
                        <td
                          className="all-lists__body__td all-lists__body__td-first"
                          onClick={!isDisabled ? () => handleListSelected(tag) : undefined}
                          style={
                            isDisabled
                              ? { pointerEvents: 'none', cursor: 'not-allowed', opacity: 0.6 }
                              : {}
                          }
                        >
                          {tag.value}
                        </td>
                        <td
                          className="all-lists__body__td"
                          style={isDisabled ? { opacity: 0.6 } : {}}
                        >
                          {tag.leadCount}
                        </td>
                        <td
                          className="all-lists__body__td"
                          style={isDisabled ? { opacity: 0.6 } : {}}
                        >
                          {/* @ts-expect-error */}
                          {capitalizeFirstLetter(tag.addedBy.memberId.firstName)}{' '}
                          {/* @ts-expect-error */}
                          {capitalizeFirstLetter(tag.addedBy.memberId.lastName)}
                        </td>
                        <td
                          className="all-lists__body__td"
                          style={isDisabled ? { opacity: 0.6 } : {}}
                        >
                          {formatDate(tag.lastEditedAt)}
                        </td>
                        <td className="all-lists__body__td all-lists__body__td__action-btn">
                          <div className="dropdown">
                            <button onClick={(e) => handleActions(e, tag._id)}>
                              <img src="/assets/images/three-dots.svg" alt="Actions" />
                            </button>
                            <Animation.Appear
                              setToggle={setActiveTagId}
                              toggle={activeTagId === tag._id}
                              className={'dropdown-menu show'}
                              style={{
                                top: '2rem',
                                boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                                padding: 0,
                              }}
                              listStyle={{
                                overflow: 'auto',
                                height: 'auto',
                                width: 'auto',
                              }}
                            >
                              <ul>
                                <li onClick={() => setListToEdit(tag)}>
                                  <span>
                                    <img
                                      src="/assets/images/ic_edit_g.png"
                                      style={{
                                        width: '0.9rem',
                                        filter:
                                          'invert(15%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(50%) contrast(90%)',
                                      }}
                                      alt="rename"
                                    />
                                  </span>
                                  <FormattedMessage id="leadsLists.rename" />
                                </li>
                                <li onClick={() => setListToDelete(tag)}>
                                  <span>
                                    <img
                                      src="/assets/images/icon-delete.svg"
                                      style={{
                                        width: '0.9rem',
                                        filter:
                                          'invert(15%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(50%) contrast(90%)',
                                      }}
                                      alt="delete"
                                    />
                                  </span>
                                  <FormattedMessage id="remove" />
                                </li>
                              </ul>
                            </Animation.Appear>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div style={{ width: '100%' }}>
                <div className="leads-table-footer all-list-pagination">
                  <div style={{ display: 'flex' }} data-tracking-id={'leads.filters-pagination'}>
                    <label style={{ margin: '14px 12px 0px 12px', color: '#595F79' }}>
                      <FormattedMessage id={'page'} /> :
                    </label>
                    <div style={{ marginTop: 5 }}>
                      <PaginationInput
                        pages={state.allListsPages && state.allListsPages.pages}
                        currentPage={tagPage}
                        onChange={handleTagsGoToPage}
                        lock={planIsLimitedPermission}
                      />
                    </div>
                    <label style={{ margin: '14px 12px 0px 12px', color: '#595F79' }}>
                      <FormattedMessage
                        id={'ofPages'}
                        values={{ value: state.allListsPages && state.allListsPages.pages }}
                      />
                    </label>
                  </div>
                </div>
              </div>
              {listToDelete && (
                <ModalMessage
                  toggle={!!listToDelete}
                  setToggle={() => {
                    setListToDelete(null);
                  }}
                  title={`${intl.formatMessage({ id: 'delete' })} ${listToDelete && listToDelete.value}`}
                  textElement={
                    <>
                      <strong>
                        <FormattedMessage id={'leads.pleaseConfirmARemovalOf'} />{' '}
                        {listToDelete && listToDelete.value}
                      </strong>
                      <span style={{ color: '#666', display: 'block' }}>
                        <FormattedMessage id={'lead.deletedList'} />
                      </span>
                    </>
                  }
                  action={'Confirm'}
                  tracking={{
                    confirm: 'leads.deleteList-confirm',
                    cancel: 'leads.deleteList-cancel',
                  }}
                  handleAction={() => handleDeteleTagList(listToDelete)}
                />
              )}
              {listToEdit && (
                <CreateNewListModal
                  toggle={listToEdit}
                  setToggle={setListToEdit}
                  label={listToEdit}
                  tracking={{
                    action: 'leads.filters-list-createNewListPopUp-edit',
                    cancel: 'leads.filters-list-createNewListPopUp-cancel',
                  }}
                  onTagUpdated={handleTagUpdated}
                />
              )}
            </div>
          ) : (
            <LeadsPanel
              organizationCredits={organizationCredits}
              planPermissions={planPermissions}
              credits={credits}
              profilePermissions={profilePermissions}
              organization={organization}
              exportRemaining={exportRemaining}
              hasPermission={hasPermission}
              organizationPlanPermissions={organizationPlanPermissions}
              setOrganization={setOrganization}
              errorMessage={errorMessage}
              profile={profile}
              checkPlanPermissions={checkPlanPermissions}
              checkMemberPermissions={checkMemberPermissions}
              isVersion2={isVersion2}
              remainingCredits={remainingCredits}
              hidePersonalEmails={hidePersonalEmails}
              selectedList={selectedList}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AllLists;

import { convertToJsonAndHandleErrors, getToken } from '../utils';
import { API_URL } from '../../constants';

export const leadsFilter = async ({ data, labelId }: any) => {
  if (!data) {
    data = {
      search: '',
      addedBy: '',
      date: null,
      phone: '',
      page: 1,
      sortBy: 'descending',
      labelId: labelId || '',
      labelName: '',
      automationName: '',
    };
  } else {
    if (!data.resultPerPage) data.resultPerPage = 10;
  }
  return fetch(`${API_URL}/organization/lead/filter`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const exportLeadsAsCsv = (data: any) => {
  return fetch(`${API_URL}/organization/lead/exportCsv`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  }).then(async (res) => {
    const data = await res.blob();
    const statusCode = res.status;
    return { statusCode, data };
  });
};

export const exportLeadsAsExcel = (data: any) => {
  return fetch(`${API_URL}/organization/lead/exportExcel`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  }).then(async (res) => {
    const data = await res.blob();
    const statusCode = res.status;
    return { statusCode, data };
  });
};

export const updateLead = (leads: any, labelId: any) => {
  return fetch(`${API_URL}/organization/lead/update`, {
    method: 'PUT',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify({ leads, labelId, clientDate: new Date() }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const updateLeadInfos = (lead: any) => {
  return fetch(`${API_URL}/organization/lead/updateLeadInfo`, {
    method: 'PUT',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify({ lead }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const addLeads = (lead: any) => {
  return fetch(`${API_URL}/organization/leads`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(lead),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const deleteLead = (ids: any) => {
  return fetch(`${API_URL}/organization/lead/delete`, {
    method: 'delete',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify({ ids }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const getWaitingList = (data: any) => {
  return fetch(`${API_URL}/organization/waitingList/filter`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const deleteWaitingList = (data: any) => {
  return fetch(`${API_URL}/organization/waitingList/`, {
    method: 'DELETE',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const updateWaitingList = (data: any) => {
  return fetch(`${API_URL}/organization/waitingList/`, {
    method: 'PUT',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const searchLeadActivities = (data: any) => {
  return fetch(`${API_URL}/organization/activities/search`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const searchLeadNotes = (data: any) => {
  return fetch(`${API_URL}/organization/notes/search`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const leadNoteUpdate = (data: any) => {
  return fetch(`${API_URL}/organization/notes/update`, {
    method: 'PUT',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const leadNoteDelete = (data: any) => {
  return fetch(`${API_URL}/organization/notes/delete`, {
    method: 'DELETE',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const searchLeadTasks = (data: any) => {
  return fetch(`${API_URL}/organization/tasks/search`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const addLeadNote = (data: any) => {
  return fetch(`${API_URL}/organization/notes/add`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const tagLead = (data: any) => {
  return fetch(`${API_URL}/organization/lead/tag`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const untagLead = (data: any) => {
  return fetch(`${API_URL}/organization/lead/untag`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const addLeadTag = (data: any) => {
  return fetch(`${API_URL}/organization/tags/add`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const searchLeadTag = (data: any) => {
  return fetch(`${API_URL}/organization/tags/search`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const updateLeadTag = (data: any) => {
  return fetch(`${API_URL}/organization/tags/update`, {
    method: 'PUT',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const deleteLeadTag = (data: any) => {
  return fetch(`${API_URL}/organization/tags/delete`, {
    method: 'DELETE',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const getLeadFeatures = (data: any) => {
  return fetch(`${API_URL}/organization/lead/features`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const createTask = (data: any) => {
  return fetch(`${API_URL}/organization/tasks/add`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const deleteTask = (data: any) => {
  return fetch(`${API_URL}/organization/tasks/delete`, {
    method: 'DELETE',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const updateTask = (data: any) => {
  return fetch(`${API_URL}/organization/tasks/update`, {
    method: 'PUT',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const searchTask = (data: any) => {
  return fetch(`${API_URL}/organization/tasks/search`, {
    method: 'POST',

    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',

      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';

import { LeadsContext, actions } from '../../store/leads.store';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { updateOrganizationSettings } from '../../../../services/api/organization.api';

import { Animation } from '../../../../components';
import { checkIsPlanLimited } from '../../../../services/utils/checkPermissions';
import { FormattedMessage } from 'react-intl';

function PropertyItem({ property, handleOnChange, moveProperty, index }: any) {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: 'item',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      //@ts-expect-error
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen

      const hoverBoundingRect = ref.current && ref.current.getBoundingClientRect();
      // Get vertical middle

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action

      moveProperty(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.

      //@ts-expect-error
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'item',
    item: { id: property.name, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));
  let kasprProp = property.property;
  if (kasprProp === 'tags') {
    return null;
  }
  kasprProp = kasprProp.indexOf('.') > 0 ? kasprProp.replace('.', '_') : kasprProp;
  return (
    <li
      ref={ref}
      style={{
        opacity: isDragging ? 0 : 1,
      }}
    >
      <div className="sort-icon handle">
        <img src="/assets/images/icon-drag-drop.svg" alt="" />
      </div>

      <div className="checkbox-wrap">
        <label>
          <input
            type={'checkbox'}
            defaultValue={property.property}
            checked={property.isChecked}
            onChange={handleOnChange}
          />

          <span className="checkbox-icon"></span>

          <span className="checkbox-text capital">
            <FormattedMessage id={'leads.properties.' + kasprProp} />
          </span>
        </label>
      </div>
    </li>
  );
}

function LeadsProperties({ organizationCredit }: any) {
  //@ts-expect-error
  const { dispatch, state } = useContext(LeadsContext);
  const [toggle, setToggle] = useState(false);
  const movingProperties = state.properties.filter((prop: any) => !prop.isFixed);
  let fixedProperties = state.properties.filter((prop: any) => prop.isFixed);

  const handleOnChange = (e: any) => {
    const properties = movingProperties;
    const property = properties.find((property: any) => property.property === e.target.value);
    property.isChecked = e.target.checked;
    dispatch({
      type: actions.CHANGE_PROPERTIES,
      payload: [...fixedProperties, ...properties],
    });
  };
  const handleOnSubmit = (e: any) => {
    e.preventDefault();
  };

  const moveProperty = useCallback(
    (dragIndex: any, hoverIndex: any) => {
      let dragProperty = movingProperties[dragIndex];
      if (dragProperty) {
        dispatch({
          type: actions.CHANGE_PROPERTIES,
          payload: [
            ...fixedProperties,
            ...update(movingProperties, {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragProperty],
              ],
            }),
          ],
        });
      }
    },
    [state.properties],
  );

  const resetPropertiesSettings = async (e: any) => {
    e.preventDefault();
    dispatch({ type: actions.RESET_PROPERTIES });
  };
  const savePropertiesSettings = async () => {
    let properties = state.properties;
    await updateOrganizationSettings({
      settings: {
        leadsColumns: properties.map((property: any) => ({
          ...property,
          isChecked: !!(property.isChecked || property.isFixed),
        })),
      },
    });
    setToggle(false);
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="dropdown customize-table-view-ui">
        <button
          className="dropdown-toggle bx-btn"
          type="button"
          onClick={() => setToggle(true)}
          data-tracking-id={'leads.filters-leadsProperties'}
        >
          <img src="/assets/images/icon_table_customize.svg" alt="" />
        </button>

        <Animation.SlideDown
          toggle={toggle}
          setToggle={setToggle}
          className="dropdown-menu show"
          style={{
            width: 'max-content',
            maxContent: 'max-content',
            paddingBottom: 0,
          }}
        >
          <form onSubmit={handleOnSubmit} style={{ margin: '10px 0' }}>
            <div className="dropdown-header">
              <FormattedMessage id={'leads.customizeTableView'} />
            </div>

            <ul className="dropdown-content">
              {movingProperties.map((item: any, i: any) => (
                <PropertyItem
                  property={item}
                  key={i}
                  index={i}
                  handleOnChange={handleOnChange}
                  moveProperty={moveProperty}
                />
              ))}
            </ul>

            <div
              className="button-blocks"
              style={{
                position: 'sticky',
                padding: '20px 3px',
                bottom: 0,
                background: '#fff',
                borderTop: '1px solid rgba(213, 215, 228, 0.48)',
                zIndex: 10,
              }}
            >
              <a
                href="#"
                className="btn btn2"
                onClick={resetPropertiesSettings}
                data-tracking-id={'leads.filters-leadsProperties-reset'}
              >
                <FormattedMessage id={'reset'} />
              </a>

              <button
                disabled={checkIsPlanLimited({
                  permissions: organizationCredit && organizationCredit.plan.permissions,
                  name: 'Leads',
                })}
                className="btn btn1"
                onClick={savePropertiesSettings}
                data-tracking-id={'leads.filters-leadsProperties-saveView'}
              >
                {checkIsPlanLimited({
                  permissions: organizationCredit && organizationCredit.plan.permissions,
                  name: 'Leads',
                }) && (
                  <img src="/assets/images/lock.png" style={{ width: 15, marginRight: 7 }} alt="" />
                )}{' '}
                <FormattedMessage id={'leads.saveView'} />
              </button>
            </div>
          </form>
        </Animation.SlideDown>
      </div>
    </DndProvider>
  );
}

export default LeadsProperties;

import React, { useContext, useEffect, useState } from 'react';
import LeadsItem from './LeadsItem/LeadsItem';
import { LeadsContext, actions } from '../../store/leads.store';
import { ModalMessage } from '../../../../components';
import { getAccounts } from '../../../../services/api/integrations.api';
import { FormattedMessage, useIntl } from 'react-intl';
import { leadsFilter, updateLeadInfos } from '../../../../services/api/leads.api';
import { toast } from 'react-toastify';
import { getProfileData } from '../../../../services/api/profile.api';

function LeadsList({
  handleEmailToggle,
  setEmailToggle,
  emailToggle,
  setPermissionToggle,
  permissionToggle,
  setSelectedLead,
  selectedLead,
  isVersion2,
  organization,
  hidePersonalEmails,
  selectedList,
  leads,
}: any) {
  //@ts-expect-error
  const { state, dispatch } = useContext(LeadsContext);
  const [templateToggle, setTemplateToggle] = useState(false);
  const [template, setTemplate] = useState('');
  const [subject, setSubject] = useState('');
  const [account, setAccount] = useState(false);
  const [filteredLeads, setFilteredLeads] = useState<any[]>([]);

  const intl = useIntl();

  const handleTemplateChange = (value: any, type: any) => {
    if (type === 'template') setTemplate(value);
    else setSubject(value);
  };

  const handleTemplateToggle = () => {
    setEmailToggle(!emailToggle);
    setTemplateToggle(!templateToggle);
  };

  const properties = state.properties.filter((prop: any) => prop.isChecked || prop.isFixed);

  const handleSelectLead = (isChecked: any, lead: any) => {
    setSelectedLead(lead);
    const updatedLeads = state.leads.map((l: any) => {
      if (l._id === lead._id) {
        return { ...l, isChecked };
      }
      return l;
    });
    dispatch({ type: actions.GET_LEADS, payload: updatedLeads, pages: state.pages });
    //@ts-expect-error
    dispatch({ type: actions.LOADING, payload: false });
  };

  const handleSelectAllLeads = (e: any) => {
    const updatedLeads = state.leads.map((lead: any) => ({
      ...lead,
      isChecked: e.target.checked,
    }));
    dispatch({ type: actions.GET_LEADS, payload: updatedLeads, pages: state.pages });
    //@ts-expect-error
    dispatch({ type: actions.LOADING, payload: false });
  };

  useEffect(() => {
    getAccounts().then(({ data }) => {
      //@ts-expect-error
      if (data && data.accounts && data.accounts.gmail && data.accounts.gmail.gmailEmail)
        setAccount(true);
    });
  }, []);

  const handleDataDiscover = async ({ lead, type }: any) => {
    const { data, statusCode } = await getProfileData({
      name: lead.name,
      id: lead.linkedin,
      types: [type],
      source: 'Linkedin',
    });

    if (statusCode === 200) {
      const dataErrorTypes: any = {
        legacyCredits: false,
        workEmailCredits: 'No B2B email found',
        personalEmailCredits: 'No direct email found',
        phoneCredits: 'No phone found',
        exportCredits: false,
      };

      //@ts-expect-error
      if (!data.discovered[type + 'Credits']) {
        return toast.error(dataErrorTypes[type + 'Credits']);
      }

      //@ts-expect-error
      const leadToUpdate = { ...lead, ...data.profile };
      const { statusCode: updateLeadStatusCode, data: leadUpdatedData } =
        await updateLeadInfos(leadToUpdate);

      if (updateLeadStatusCode === 200) {
        const leadsData = { ...state.dataForm, page: 1 };
        if (selectedList && selectedList._id) {
          leadsData.tagsIds = [selectedList._id];
        }

        const results = await leadsFilter({ data: leadsData });

        //@ts-expect-error
        const leads = results.data.leads || [];
        setFilteredLeads(leads);
        dispatch({
          type: actions.GET_LEADS,
          payload: leads,
          //@ts-expect-error
          pages: results.data.pages,
        });
      } else {
        //@ts-expect-error
        toast.error(leadUpdatedData.message);
      }
    } else {
      //@ts-expect-error
      toast.error(data.message);
    }
  };

  return (
    <div className="table-content">
      <table width="100%">
        <thead>
          <tr>
            <th>
              <div className="checkbox-wrap" data-tracking-id={'leads.leadsList-selectAllLeads'}>
                <label>
                  <input type="checkbox" value="" onChange={handleSelectAllLeads} />
                  <span className="checkbox-icon"></span>
                </label>
              </div>
            </th>
            {properties.map((prop: any, i: any) => (
              <th key={i}>
                <FormattedMessage id={'leads.properties.' + prop.property} />
              </th>
            ))}
            <th key={properties && properties.length} style={{ width: '50px' }}>
              {' '}
            </th>
          </tr>
        </thead>

        <tbody>
          {leads && leads.length > 0 ? (
            leads.map((lead: any, index: any) => (
              <LeadsItem
                key={lead._id}
                itemIndex={index}
                lead={lead}
                properties={properties}
                onLeadSelect={handleSelectLead}
                handleTemplateToggle={() => handleEmailToggle(lead)}
                onDataDiscover={handleDataDiscover}
                isVersion2={isVersion2}
                organization={organization}
                hidePersonalEmails={hidePersonalEmails}
              />
            ))
          ) : (
            <tr>
              <td colSpan={properties.length + 2}>
                <FormattedMessage id={'leads.noLeadsFound'} />
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <ModalMessage
        toggle={permissionToggle}
        setToggle={() => setPermissionToggle(!permissionToggle)}
        title={intl.formatMessage({ id: 'permissions.denied' })}
        textElement={
          account ? (
            <>
              <FormattedMessage id={'permissions.upgradePlan'} /> <br />
              <a
                target="_blank"
                href=""
                onClick={() => (window.location.href = '/billing')}
                rel="noopener noreferrer nofollow"
              >
                <FormattedMessage id={'settings.clickHereToCheckOurDeals'} />
              </a>
            </>
          ) : (
            <>
              <FormattedMessage id={'settings.youNeedConnectToGmail'} /> <br />
              <a
                target="_blank"
                href=""
                onClick={() => (window.location.href = '/settings?integration=true')}
                rel="noopener noreferrer nofollow"
              >
                <FormattedMessage id={'settings.clickHereToConnect'} />
              </a>
            </>
          )
        }
        action={'Ok'}
        handleAction={() => setPermissionToggle(!permissionToggle)}
        forbidCancel={true}
      />
    </div>
  );
}

export default LeadsList;

import React, { useContext, useEffect, useState } from 'react';
import './EnrichmentOptions.css';
import { FormattedMessage } from 'react-intl';

import EnrichmentOption from '../EnrichmentOption/EnrichmentOption';

import ProgressSteps from '../../common/ProgressSteps/ProgressSteps';
import { getEnrichedCSV } from '../../../../services/api/bulkEnrichment.api';
import actions from '../../store/bulkEnrichment.actions';
import { BulkEnrichmentContext } from '../../store/bulkEnrichment.store';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EnrichmentOptions() {
  //@ts-expect-error
  const { state, dispatch } = useContext(BulkEnrichmentContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const updateEnrichmentOption = (
    enrichmentOptionKey: any,
    isSelected: any,
    value: any,
    mandatory: any,
  ) => {
    const data = { isSelected, value, mandatory };
    dispatch({
      type: actions.UPDATE_ENRICHMENT_DATA,
      payload: { enrichmentOptionKey, data },
    });
  };
  const handleGoBack = () => {
    dispatch({
      type: actions.CURRENT_STEP,
      payload: 1,
    });
    dispatch({
      type: actions.IS_MODAL_OPEN,
      payload: true,
    });
    dispatch({
      type: actions.SET_FIRSTNAME_COLUMN,
      payload: '',
    });
    dispatch({
      type: actions.SET_LASTNAME_COLUMN,
      payload: '',
    });
  };

  const handleProceed = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    let enrichments;
    enrichments = state.enrichmentData;
    enrichments.yes = { value: 'yes' };
    enrichments.no = { value: 'no' };

    const formData = new FormData();
    formData.append('file', state.csvContent);
    formData.append('enrichmentData', JSON.stringify(enrichments));
    formData.append('tags', JSON.stringify([state.selectedLabel[0]._id]));
    formData.append(
      'columns',
      JSON.stringify({
        linkedin: state.linkedinColumn,
        firstName: state.firstnameColumn,
        lastName: state.lastnameColumn,
      }),
    );

    try {
      const { data, statusCode } = await getEnrichedCSV(formData);
      //@ts-expect-error
      if (statusCode === 200 && data.success) {
        toast.success(
          <FormattedMessage id="bulkEnrichment.enrichmentOptions.fileSuccessfullySaved" />,
        );
        setTimeout(() => {
          window.location.href = '/bulk-enrichment/files';
        }, 3000);
      } else {
        //@ts-expect-error
        toast.error(data.messageTranslated || data.message);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  };

  return (
    <div className={'modal-container'}>
      <div className={'modal-container-top'}>
        <h1>
          <FormattedMessage id={'bulkEnrichment.uploadFiles.enrichmentOptions'} />
        </h1>

        <div id="enrichment-options" className={'enrichment-options-cards'}>
          <EnrichmentOption
            enrichmentOptionKey="phone"
            titleId="bulkEnrichment.uploadFiles.phoneEnrichment"
            value={'phone'}
            updateOption={updateEnrichmentOption}
          />

          <EnrichmentOption
            enrichmentOptionKey="workEmail"
            titleId="bulkEnrichment.uploadFiles.b2bEmailEnrichment"
            value={'workEmail'}
            updateOption={updateEnrichmentOption}
          />

          <EnrichmentOption
            enrichmentOptionKey="directEmail"
            titleId="bulkEnrichment.uploadFiles.personalEmailEnrichment"
            value={'directEmail'}
            updateOption={updateEnrichmentOption}
          />
        </div>

        <div className={'mandatory-desc'}>
          <img
            src={'/assets/images/ic-info.png'}
            alt={'info icon'}
            width={'15px'}
            height={'15px'}
          />

          <h3>
            <FormattedMessage id={'bulkEnrichment.uploadFiles.mandatoryDesc'} />
          </h3>
        </div>
      </div>
      {/*FOOTER*/}

      <div className={'modal-container-bottom'}>
        <ProgressSteps />

        <div className={'modal-container-bottom-buttons'}>
          <a onClick={handleGoBack} className="btn btn2 btn-md mr14">
            <FormattedMessage id={'bulkEnrichment.settings.goBack'} />
          </a>

          <a className="btn btn1 btn-md" onClick={handleProceed}>
            <FormattedMessage id={'bulkEnrichment.enrichmentOptions.proceed'} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default EnrichmentOptions;
